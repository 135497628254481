<template>
  <div class="manage-branch-bank-accounts container fluid">
    <div class="card">
      <div class="d-flex align-items-center p-2 mb-4">
        <fd-button class="circle mr-1" @click="goBack">
          <i class="fas fa-chevron-left"></i>
        </fd-button>
        <h2>Manage Branch Bank Account</h2>
      </div>

      <!-- ========================= Table Listing ========================= -->
      <h4 class="p-2">Bank Accounts</h4>
      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :columns="bankAccountTableColumns"
        :rows="bankAccountTableData"
        :totalRows="bankAccountTablePagination.total"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: perPageOptions,
          dropdownAllowAll: false
        }"
        :sort-options="{
          enabled: false
        }"
      >
        <div slot="emptystate">
          <no-data message="There is no data"></no-data>
        </div>
        <div slot="table-actions" class="p-2">
          <fd-button class="main mr-2" @click="openBankAccountModal()">
            <i class="fas fa-plus"></i>
          </fd-button>
          <!-- <fd-button
            :class="{ active: isFilterActive }"
            class="toggle"
            @click="filterToggle"
          >
            <i class="fas fa-filter"></i>
          </fd-button> -->
        </div>
        <!-- Table Buttons -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <!-- Edit Bank Account -->
            <fd-button
              class="main bordered mr-1"
              @click="openBankAccountModal(true, props.row)"
            >
              <i class="fas fa-pen"></i>
            </fd-button>
            <!-- Delete Bank Account -->
            <fd-button
              class="danger bordered"
              @click="deleteBankAccount(props.row.id)"
            >
              <i class="fas fa-trash"></i>
            </fd-button>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
    <!-- ===================== Modal: Add Bank Account ===================== -->
    <modal v-model="bankAccountModal.isShown">
      <bank-account
        :isEdit="bankAccountModal.isEdit"
        :branchId="$route.params.id"
        :bankAccount="bankAccountModal.bankAccountToEdit"
        @cancel="bankAccountModal.isShown = false"
        @create="addBankAccount"
        @update="updateBankAccount"
      ></bank-account>
    </modal>
  </div>
</template>

<script>
import { branchBankAccount as bankAccAPI } from "@/api";
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  components: {
    BankAccount: () => import("@/components/Branch/BankAccount")
  },
  mixins: [vueGoodTable],
  props: {},
  data: function () {
    return {
      bankAccountTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false,
          width: "180px"
        },
        {
          label: "Bank Name",
          field: "bank"
        },
        {
          label: "Account No.",
          field: "bankAccountNumber"
        },
        {
          label: "Account Name",
          field: "bankAccountName"
        },
        {
          label: "Swift Number",
          field: "swiftNumber"
        },
        {
          label: "Last Updated",
          field: "updatedAt",
          formatFn: (date) => this.$moment(date).format("DD-MM-YYYY")
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      bankAccountTableData: [],
      bankAccountTablePagination: {},

      filter: {},
      isFilterActive: false,

      bankAccountModal: {
        isEdit: false,
        isShown: false,
        bankAccountToEdit: {}
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.getAllBankAccounts();
  },
  methods: {
    goBack() {
      this.$router.replace({ name: "ManageBranch" });
    },
    openBankAccountModal(isEdit = false, bankAccount = {}) {
      this.bankAccountModal.isEdit = isEdit;
      this.bankAccountModal.bankAccountToEdit = bankAccount;
      this.bankAccountModal.isShown = true;
    },
    // ========================= Table related methods =========================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllBankAccounts();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllBankAccounts();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllBankAccounts();
    },
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getAllBankAccounts();
    },
    // ============================== API Related ==============================
    async getAllBankAccounts() {
      try {
        this.$store.commit("setIsLoading", true);

        let filteredParam = this.$cleanQueryParam(this.filter);

        let response = await bankAccAPI.getAllBankAccounts(
          this.$route.params.id,
          {
            queries: {
              ...filteredParam
            },
            page: this.tableParams.page,
            limit: this.tableParams.perPage
          }
        );

        this.bankAccountTableData = this._.cloneDeep(response.data);
        this.bankAccountTablePagination = this._.cloneDeep(
          response.meta.pagination
        );
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get branch's bank accounts. Please try again later."
        });
        throw error;
      }
    },

    async addBankAccount(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await bankAccAPI.createBankAccount(payload);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Bank account has been created successfully."
        });
        this.bankAccountModal.isShown = false;
        this.getAllBankAccounts();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text:
            "Failed to add new bank account for the branch. Please try again later."
        });
      }
    },
    async updateBankAccount(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await bankAccAPI.updateBankAccount(id, payload);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Bank account has been updated successfully."
        });
        this.bankAccountModal.isShown = false;
        this.getAllBankAccounts();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update the bank account. Please try again later."
        });
      }
    },

    async deleteBankAccount(id) {
      let confirmDelete = await this.$confirm({
        type: "alert",
        message:
          "Are you sure you want to delete this bank account? This action cannot be undone.",
        confirmText: "Delete"
      });

      if (confirmDelete) {
        try {
          this.$store.commit("setIsLoading", true);
          await bankAccAPI.deleteBankAccount(id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Deleted",
            text: "Bank account has been deleted successfully."
          });
          this.getAllBankAccounts();
          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to delete the bank account. Please try again later."
          });
          throw error;
        }
      }
    }
  }
};
</script>

<style lang="scss"></style>
